<template>
  <Modal
    title="查看借用单"
    v-model="showForm"
    :mask-closable="false"
    width="1200px"
  >
    <div class="divider">借用信息</div>
    <Form ref="viewForm" label-colon :label-width="90">
      <Row>
        <Col span="12">
          <FormItem label="借用单号" prop="orderNumber">
            {{ viewForm.orderNumber }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="资产数量" prop="quantity">
            {{ viewForm.quantity }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="借用时间" prop="borrowTime">
            {{
              viewForm.borrowTime === null
                ? ''
                : this.$moment(viewForm.borrowTime).format('YYYY-MM-DD')
            }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="预计归还时间" prop="givebackTime">
            {{
              viewForm.givebackTime === null
                ? ''
                : this.$moment(viewForm.givebackTime).format('YYYY-MM-DD')
            }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="审批状态" prop="approvalStatus">
            <div
              :style="{
                color:
                  viewForm.approvalStatus === 0
                    ? '#ff9900'
                    : viewForm.approvalStatus === 1
                    ? '#ed4014'
                    : '#19be6b',
              }"
            >
              {{
                viewForm.approvalStatus === 0
                  ? '审批中'
                  : viewForm.approvalStatus === 1
                  ? '驳回'
                  : '通过'
              }}
            </div>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="图片">
            <div
              class="upload-image-list"
              v-for="item in attachmentList"
              v-if="item.type === 'img'"
            >
              <template>
                <img :src="downloadFileURL + item.currentName + '/'" />
                <div class="upload-image-list-cover">
                  <Icon
                    type="ios-eye"
                    @click.native="handleView(item.currentName)"
                  ></Icon>
                </div>
              </template>
            </div>
          </FormItem>
          <Modal title="查看图片" v-model="visible" footer-hide>
            <img :src="imageUrl" v-if="visible" style="width: 100%" />
          </Modal>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="使用人" prop="userName">
            {{ viewForm.userName }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="使用部门" prop="userDeptName">
            {{ viewForm.userDeptName }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="创建人" prop="createName">
            {{ viewForm.createName }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="创建时间" prop="createTime">
            {{
              this.$moment(viewForm.createTime).format('YYYY-MM-DD HH:mm:ss')
            }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <FormItem label="备注" prop="memo">
            {{ viewForm.memo }}
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div v-if="approverList.length !== 0">
      <div class="divider">审核信息</div>
      <Form class="plain-form" label-colon :label-width="90">
          <Steps direction="vertical" :current="approverList.length-1">
              <Step :title="item.psName + '：' + item.approverName" v-for="item in approverList" :key="item.id">
                  <div slot="content">
                      审核状态：
                          <span :style="{color: item.approvalStatus === 0 ? '#ff9900' : (item.approvalStatus === 1 ? '#ed4014' : '#19be6b')}">
                              {{item.approvalStatus === 0 ? '审核中' : (item.approvalStatus === 1 ? '驳回' : '通过')}}
                          </span>，
                      审核时间：{{item.approvedTime}}，
                      审核意见：{{item.approvalComment ? item.approvalComment : '无'}}
                  </div>
              </Step>
          </Steps>
      </Form>
    </div>
    <div class="divider">资产信息</div>
    <div class="table">
      <div class="table-list">
        <Table stripe :columns="columns" :data="dataList"> </Table>
      </div>
    </div>
    <div slot="footer">
      <Button type="primary" @click="closeModal(false)">确定</Button>
    </div>
  </Modal>
</template>

<script>
  import { reqGetInfoByBorrowId } from '../../../../api/asset-api'
  import { downloadFileURL } from '../../../../api/system-api'

  export default {
    name: 'viewBorrow',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      borrowId: '',
    },
    data() {
      return {
        showForm: false,
        viewForm: {
          orderNumber: '',
          userName: '',
          userDeptName: '',
          borrowTime: '',
          givebackTime: '',
          quantity: 0,
          approvalStatus: '',
          createName: '',
          createTime: '',
        },
        columns: [
          {
            title: '资产编码',
            key: 'encoding',
            tooltip: 'ture',
            minWidth: 200,
          },
          {
            title: '资产名称',
            key: 'assetName',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: '资产分类',
            key: 'classifyName',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '品牌',
            key: 'brand',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '型号',
            key: 'model',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: 'SN码',
            key: 'snCode',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: '所在位置',
            key: 'locationName',
            tooltip: 'ture',
            width: 150,
          },
        ],
        dataList: [],
        approverList: [],
        attachmentList: '',
        visible: false,
        imageUrl: '',
        downloadFileURL: downloadFileURL,
      }
    },
    watch: {
      value(val) {
        this.showForm = val
      },
      showForm(val) {
        if (val) {
          reqGetInfoByBorrowId({ id: this.borrowId }).then((res) => {
            this.dataList = res.data.data[0].assetList
            this.viewForm = res.data.data[0]
            this.attachmentList = res.data.data[0].attachmentList
            this.approverList = res.data.data[0].approverList
          })
        } else {
          // 反之则关闭页面
          this.closeModal(val)
        }
      },
    },
    methods: {
      closeModal(val) {
        this.$emit('input', val)
      },
      handleView(url) {
        this.imageUrl = downloadFileURL + url + '/'
        this.visible = true
      },
      downloadFile(currentName) {
        var downloadElement = document.createElement('a')
        var href = downloadFileURL + currentName + '/'
        downloadElement.href = href
        downloadElement.download = 'Asset.xlsx' //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      },
    },
    mounted() {},
  }
</script>
